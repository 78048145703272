<template>
<tbody v-if="signa.template_id==1">
        <tr valign="top">
            <td>
                <div style="">
                    <table border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr valign="top">
                                <td>
                                    <div style="padding: 15px;border-radius: 10px;background-color: #fff;border:solid 1px rgb(221, 221, 221);border-right:solid 2px rgb(221, 221, 221);">
                                        <table border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr valign="top">
                                                    <!-- left column -->
                                                    <td v-if="active_profile">
                                                        <table border="0" cellspacing="0" cellpadding="0">
                                                            <tbody>
                                                                <tr valign="top">
                                                                    <td>
                                                                        <p style="margin: 1px;">
                                                                            <img :src="getAvatar" alt="" title="Profile Picture" width="65" height="65" style="display: block; border: 0px; max-width: 65px; height: 65px;border-radius: 50%;">
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <!-- /left column -->
                                                    
                                                    <!-- empty space -->
                                                    <td width="15"><div style="width: 15px;"></div></td>
                                                    <!-- /empty space -->
                                                    
                                                    <!-- right column -->
                                                    <td>
                                                        <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;">
                                                            <tbody>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; text-decoration: none;">
                                                                        <div style="width: auto; max-width: auto;">
                                                                            <p :style="'font-family: Arial, Helvetica, sans-serif; font-size: 15px; line-height: 18px; font-weight: 400; color:'+signa.color_nombre+'; white-space: normal; margin: 0px;'">{{nombre}}</p>
                                                                            <p style="font-family: Arial, Helvetica, sans-serif; font-size: 11px; line-height: 14px; white-space: normal; color:#828282; margin: 0px;">{{trabajo}}</p>
                                                                            </div>
                                                                    </td>
                                                                </tr>
                                                                <tr valign="top"><td height="5"><div style="height: 5px;"></div></td> </tr>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; text-decoration: none;">
                                                                        <div style="width: auto; max-width: auto;">
                                                                            <a style="margin-bottom:0px;display:block;" :href="openLinckard()" target="_blank"><img :src="url_node+'/bc'+$i18n.locale+'/btn/'+signa.color_btn_principal.replace('#','')+'.png'" width="114" /></a>    
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <!-- /right column -->
                    
                                                </tr>
                                            </tbody>
                                        </table>
                    
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                
                
                
                </div>
            </td>
        </tr>
</tbody>
<tbody v-else-if="signa.template_id==2">
        <tr valign="top">
            <td>
                <div style="">
                
                    <table border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr valign="top">
                                <td>
                                    <div style="padding: 15px;border-radius: 10px;background-color: #fff;border:solid 1px rgb(221, 221, 221);">
                                        <table border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr valign="top">
                                                    <td v-if="active_profile">
                                                        <table border="0" cellspacing="0" cellpadding="0">
                                                            <tbody>
                                                                <tr valign="top">
                                                                    <td>
                                                                        <p style="margin: 1px;">
                                                                            <img :src="getAvatar" alt="" title="Profile Picture" width="65" height="65" style="display: block; border: 0px; max-width: 65px; height: 65px;border-radius: 50%;">
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td width="15"><div style="width: 15px;"></div></td>
                                                    <td>
                                                        <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;">
                                                            <tbody>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;">
                                                                        <div style="width: 115px; max-width: 115px;">
                                                                            <p :style="'font-family: Arial, Helvetica, sans-serif; font-size: 15px; line-height: 18px; font-weight: 400; color:'+signa.color_nombre+'; white-space: normal; margin: 0px;'">{{nombre}}</p>
                                                                            <p style="font-family: Arial, Helvetica, sans-serif; font-size: 11px; line-height: 14px; white-space: normal; color:#828282; margin: 0px;">{{trabajo}}</p>
                                                                            </div>
                                                                    </td>
                                                                </tr>
                                                                <tr valign="top"><td height="5"><div style="height: 5px;"></div></td> </tr>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;">
                                                                        <div style="width: 115px; max-width: 115px;">
                                                                            <a style="margin-bottom:0px;display:block;" :href="openLinckard()" target="_blank"><img :src="url_node+'/bc'+$i18n.locale+'/btn/'+signa.color_btn_principal.replace('#','')+'.png'" width="114" /></a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td width="15"><div style="width: 15px;"></div></td>
                                                    <td width="1" style="border-left: solid 1px rgb(221, 221, 221);"><div style="width: 1px;height: 100%;"></div> </td>
                                                    <td width="15"><div style="width: 15px;"></div></td>
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0">
                                                            <tbody>
                                                                <tr valign="top">
                                                                    <td>
                                                                        <p style="margin: 1px;">
                                                                            <img :src="getLogoBusinessFirma(preview)" @error="function(event){event.target.src=getLogoBusinessFirma(preview,null)}" alt="" title="Profile Picture" width="65" height="65" style="display: block; border: 0px; max-width: 65px; height: 65px;">
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <!-- /right column -->
                    
                                                </tr>
                                            </tbody>
                                        </table>
                    
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                
                
                
                </div>
            </td>
        </tr>
</tbody>
<tbody v-else-if="signa.template_id==3">
        <tr valign="top">
            <td>
                <div style="">
                    <table border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr valign="top">
                                <td>
                                    <div style="">
                                        <table border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr valign="top">
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0">
                                                            <tbody>
                                                                <tr v-if="active_profile" valign="top">
                                                                    <td><p style="margin: 1px;"><img :src="getAvatar"  alt="" title="Profile Picture"  width="65" height="65" style="display: block; border: 0px; max-width: 65px; height: 65px;border-radius: 50%;"></p></td>
                                                                </tr>
                                                                <tr valign="top"><td height="15"> <div style="height: 15px;"></div></td>
                                                                </tr>
                                                                <tr valign="top"><td><p style="margin: 1px;"><img :src="getLogoBusinessFirma(preview)" @error="function(event){event.target.src=getLogoBusinessFirma(preview,null)}" alt="" title="Profile Picture"   width="65" height="65" style="display: block; border: 0px; max-width: 65px; height: 65px;"></p> </td></tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td width="15"><div style="width: 15px;"></div> </td>
                                                    <td width="1" style="border-left: solid 1px rgb(221, 221, 221);"><div style="width: 1px;height: 100%;"></div> </td>
                                                    <td width="15"><div style="width: 15px;"></div></td>
                                                    <td>
                                                        <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;">
                                                            <tbody>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;">
                                                                        <div style="width: auto; max-width: 100%;">
                                                                            <p :style="'font-family: Arial, Helvetica, sans-serif; font-size: 15px; line-height: 18px; font-weight: 400; color:'+signa.color_nombre+'; white-space: normal; margin: 0px;'">{{nombre}}</p>
                                                                            <p style="font-family: Arial, Helvetica, sans-serif; font-size: 11px; line-height: 14px; white-space: normal; color:#828282; margin: 0px;">{{trabajo}}</p>
                                                                            </div>
                                                                    </td>
                                                                </tr>
                                                                <tr valign="top"><td height="5"><div style="height: 5px;"></div></td> </tr>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;">
                                                                        <div style="width: auto; max-width: 100%;">
                                                                            <a style="margin-bottom:0px;display:block;" :href="openLinckard()" target="_blank"><img :src="url_node+'/bc'+$i18n.locale+'/btn/'+signa.color_btn_principal.replace('#','')+'.png'" width="114" /></a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;">
                                                                        <div style="border-bottom: solid 1px rgb(221, 221, 221);">
                                                                            <table border="0" cellpadding="0" cellspacing="0">
                                                                                <tbody>
                                                                                    <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>
                                                                                    
                                                                                    <tr style="padding-top: 10px;">
                                                                                        <td align="left" style="padding-right: 15px; text-align: center; padding-top: 0;" v-if="isEmailTop || isEmailBottom"  ><a  :href="openUrl('mailto:'+email)"  style="margin-bottom:0px;display:block;" target="_blank"><img :src="url_node+'/email/icon/'+signa.color_btn_sec.replace('#','')+'.png'" width="20" /></a></td>
                                                                                        <td align="left" style="padding-right: 15px; text-align: center; padding-top: 0;" v-if="isWebsiteBottom || isWebsiteTop" ><a  :href="openUrl('tel:'+mobilephone)"  style="margin-bottom:0px;display:block;" target="_blank"><img :src="url_node+'/url/icon/'+signa.color_btn_sec.replace('#','')+'.png'" width="20" /></a></td>
                                                                                        <td align="left" style="padding-right: 15px; text-align: center; padding-top: 0;" v-if="isMobilephone" ><a  :href="openUrl('tel:'+mobilephone)"  style="margin-bottom:0px;display:block;" target="_blank"><img :src="url_node+'/mobilephone/icon/'+signa.color_btn_sec.replace('#','')+'.png'" width="20" /></a></td>
                                                                                        <td align="left" style="padding-right: 15px; text-align: center; padding-top: 0;" v-if="isFixed" ><a :href="openUrl('tel:'+phone)" style="margin-bottom:0px;display:block;" target="_blank"><img :src="url_node+'/phone/icon/'+signa.color_btn_sec.replace('#','')+'.png'" width="20" /></a></td>
                                                                                        <td align="left" style="padding-right: 15px; text-align: center; padding-top: 0;" v-if="isWhatsapp" ><a :href="openUrl(getWhatsappUrl)" style="margin-bottom:0px;display:block;" target="_blank"><img :src="url_node+'/whatsapp/icon/'+signa.color_btn_sec.replace('#','')+'.png'" width="20" /></a></td>
                                                                                        <td align="left" style="padding-right: 15px; text-align: center; padding-top: 0;" v-if="isCalendar" ><a :href="openUrl(calendar)" style="margin-bottom:0px;display:block;" target="_blank"><img :src="url_node+'/calendar/icon/'+signa.color_btn_sec.replace('#','')+'.png'" width="20" /></a></td>
                                                                                    </tr>
                                                                                    <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div style="">
                                                                            <table border="0" cellpadding="0" cellspacing="0">
                                                                                <tbody> 
                                                                                    <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>
                                                                                
                                                                                    <iconssocial :signature="signa" :linkcard="preview" parent="padding-top: 10px;" child="padding-right: 15px; text-align: center; padding-top: 0;" align="left"/>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </td>
        </tr>
</tbody>
<tbody v-else-if="signa.template_id==4">
        <tr valign="top">
            <td>
                <div style="">
                
                    <table border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr valign="top">
                                <td>
                                    <div style="">
                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="center" style="text-align: center;width: 100%; max-width: 100%;">
                                            <tbody>

                                                <!-- first row -->
                                                <tr v-if="active_profile" valign="top">
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="center" style="text-align: center;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top">
                                                                    <td>
                                                                        <p style="margin: 1px;">
                                                                            <img :src="getAvatar" alt="" title="Profile Picture" width="65" height="65" style="display: inline-block; border: 0px; max-width: 65px; height: 65px;border-radius: 50%;">
                                                                        </p>
                                                                    </td>
                                                                </tr>

                                                                <tr valign="top">
                                                                    <td height="10"><div style="height: 10px;"></div></td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                
                                                <tr valign="top">
                                                    <td>
                                                        <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;width:auto;margin:0 auto;" >
                                                            <tbody>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;text-align:center;">
                                                                        <div style="width: auto; max-width: 100%;">
                                                                            <p :style="'font-family: Arial, Helvetica, sans-serif; text-align:center;font-size: 15px; line-height: 18px; font-weight: 400; color:'+signa.color_nombre+'; white-space: normal; margin: 0px;'">{{nombre}}</p>
                                                                            <p style="font-family: Arial, Helvetica, sans-serif;text-align:center; font-size: 11px; line-height: 14px; white-space: normal; color:#828282; margin: 0px;">{{trabajo}}</p>
                                                                            </div>
                                                                    </td>
                                                                </tr>
                                                                <tr valign="top"><td height="5"><div style="height: 5px;"></div></td> </tr>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;text-align:center;">
                                                                        <div style="width: auto; max-width: 100%;">
                                                                            <a style="margin-bottom:0px;display:block;" :href="openLinckard()" target="_blank"><img :src="url_node+'/bc'+$i18n.locale+'/btn/'+signa.color_btn_principal.replace('#','')+'.png'" width="114" /></a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>
                                                                                
                                                <tr valign="top" >
                                                    <td style="border-bottom: solid 1px rgb(221, 221, 221);">
                                                        <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;width:auto;margin:0 auto;">
                                                            <tbody>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;">
                                                                        <div style="padding: 0px 0px 0px;">
                                                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="text-align: center;width: 100%; max-width: 100%;">
                                                                                <tbody>
                                                                                    <iconssocial :signature="signa" :linkcard="preview" parent="padding-top: 10px;" child="padding: 0 10px; text-align: center; padding-top: 0;" align="left"/>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>

                                                <tr valign="top">
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="center" style="text-align: center;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top">
                                                                    <td>
                                                                        <p style="margin: 1px;">
                                                                            <img :src="getLogoBusinessFirma(preview,null)" alt="" title="Profile Picture" width="auto" height="65" style="display: inline-block; border: 0px; max-width: 180px; max-height: 65px;">
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <!-- /third row -->



                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                
                
                
                </div>
            </td>
        </tr>
</tbody>
<tbody v-else-if="signa.template_id==5">
        <tr valign="top">
            <td>
                <div style="">
                    <table border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr valign="top">
                                <td>
                                    <div >
                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="left" style="text-align: left;width: 100%; max-width: 100%;">
                                            <tbody>
                                                <tr v-if="active_profile" valign="top">
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="left" style="text-align: left;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top">
                                                                    <td><p style="margin: 1px;"><img :src="getAvatar" alt="" title="Profile Picture" width="65" height="65" style="display: inline-block; border: 0px; max-width: 65px; height: 65px;border-radius: 50%;"></p></td>
                                                                </tr>
                                                                <tr valign="top"> <td height="10"><div style="height: 10px;"></div></td></tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr valign="top">
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="left" style="text-align: left;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;">
                                                                        <div style="width: auto; max-width: 100%;">
                                                                            <p :style="'font-family: Arial, Helvetica, sans-serif; font-size: 15px; line-height: 18px; font-weight: 400; color:'+signa.color_nombre+'; white-space: normal; margin: 0px;'">{{nombre}}</p>
                                                                            <p style="font-family: Arial, Helvetica, sans-serif; font-size: 11px; line-height: 14px; white-space: normal; color:#828282; margin: 0px;">{{trabajo}}</p>
                                                                        </div>    
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr valign="top"><td height="5"><div style="height: 5px;"></div></td> </tr>
                                                <tr valign="top">
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="left" style="text-align: left;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;">
                                                                        <div style="width: auto; max-width: 100%;">
                                                                            <a style="margin-bottom:0px;display:block;" :href="openLinckard()" target="_blank"><img :src="url_node+'/bc'+$i18n.locale+'/btn/'+signa.color_btn_principal.replace('#','')+'.png'" width="114" /></a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>     
                                                <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>
                                           
                                                <tr valign="top">
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="left" style="text-align: left;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top" >
                                                                    <td>   
                                                                        <div style="padding: 0px 0px 0px;">  
                                                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="line-height: 1.6; font-family: Lato; font-size: 11px; color: #4e4b4c; padding-left: 2px; font-weight: normal; width: 100%;">
                                                                                <tbody>
                                                                                    <tr v-if="isEmailTop || isEmailBottom">
                                                                                        <td style="padding-right: 10px; width: 10px; padding-bottom: 5px; vertical-align: top;"   ><img :src="url_node+'/email/icon/'+signa.color_btn_sec.replace('#','')+'.png'" width="18" /></td>
                                                                                        <td style="padding-bottom: 3px;"><a :href="openUrl('mailto:'+email)" style="vertical-align: middle; text-decoration: none !important; font-family: Arial; color: #333333; font-size: 12px;"> <span style="text-decoration: none;">{{email}}</span> </a></td>
                                                                                    </tr>
                                                                                    <tr v-if="isWebsiteBottom || isWebsiteTop" >
                                                                                        <td style="padding-right: 10px; width: 10px; padding-bottom: 5px; vertical-align: top;" ><img :src="url_node+'/url/icon/'+signa.color_btn_sec.replace('#','')+'.png'" width="18" /></td>
                                                                                        <td style="padding-bottom: 3px;"><a  :href="openUrl(website)" style="vertical-align: middle; text-decoration: none !important; font-family: Arial; color: #333333; font-size: 12px;"> <span style="text-decoration: none;">{{website}}</span> </a></td>
                                                                                    </tr>
                                                                                    <!--<tr v-if="isMobilephone">
                                                                                        <td style="padding-right: 10px; width: 10px; padding-bottom: 5px; vertical-align: top;"  ><img :src="url_node+'/mobilephone/icon/'+signa.color_btn_sec.replace('#','')+'.png'" width="18" /></td>
                                                                                        <td style="padding-bottom: 3px;"><a :href="openUrl('tel:'+mobilephone)"  style="vertical-align: middle; text-decoration: none !important; font-family: Arial; color: #333333; font-size: 12px;" target="_blank"> <span style="text-decoration: none;">{{mobilephone}}</span> </a></td>
                                                                                    </tr>-->
                                                                                    <tr v-if="isFixed" >
                                                                                        <td style="padding-right: 10px; width: 10px; padding-bottom: 5px; vertical-align: top;" ><img :src="url_node+'/phone/icon/'+signa.color_btn_sec.replace('#','')+'.png'" width="18" /></td>
                                                                                        <td style="padding-bottom: 3px;"><a :href="openUrl('tel:'+phone)" style="vertical-align: middle; text-decoration: none !important; font-family: Arial; color: #333333; font-size: 12px;" target="_blank"> <span style="text-decoration: none;">{{phone}}</span> </a></td>
                                                                                    </tr>
                                                                                    <tr v-if="isWhatsapp" >
                                                                                        <td style="padding-right: 10px; width: 10px; padding-bottom: 5px; vertical-align: top;" ><img :src="url_node+'/whatsapp/icon/'+signa.color_btn_sec.replace('#','')+'.png'" width="18" /></td>
                                                                                        <td style="padding-bottom: 3px;"><a :href="openUrl(getWhatsappUrl)" style="vertical-align: middle; text-decoration: none !important; font-family: Arial; color: #333333; font-size: 12px;" target="_blank"> <span style="text-decoration: none;">{{whatsapp}}</span> </a></td>
                                                                                    </tr>
                                                                                    <tr v-if="isCalendar">
                                                                                        <td style="padding-right: 10px; width: 10px; padding-bottom: 5px; vertical-align: top;"  ><img :src="url_node+'/calendar/icon/'+signa.color_btn_sec.replace('#','')+'.png'" width="18" /></td>
                                                                                        <td style="padding-bottom: 3px;"><a :href="openUrl(calendar)" style="vertical-align: middle; text-decoration: none !important; font-family: Arial; color: #333333; font-size: 12px;" target="_blank"> <span style="text-decoration: none;">{{calendar}}</span> </a></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr valign="top" style="border-bottom: solid 1px rgb(221, 221, 221);">
                                                    <td style="border-bottom: solid 1px rgb(221, 221, 221);">
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="left" style="text-align: left;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top" >
                                                                    <td>   
                                                                        <div style="padding: 0px 0px 0px;">
                                                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" align="left" style="text-align: left;width: 100%; max-width: 100%;">
                                                                                <tbody>
                                                                                    <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>
                                                                                    <iconssocial :signature="signa" :linkcard="preview" parent="" child="padding-right: 10px; text-align: center; padding-top: 0;" align="left"/>
                                                                                    <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr valign="top" style="border-top: solid 1px rgb(221, 221, 221);">
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="center" style="text-align: left;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>
                                                                <tr valign="top"><td> <p style="margin: 1px;"><img :src="getLogoBusinessFirma(preview,null)" alt="" title="Profile Picture" width="auto" height="65" style="display: inline-block; border: 0px; max-width: 180px; max-height: 65px;"> </p></td></tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                
                
                
                </div>
            </td>
        </tr>
</tbody>
<tbody v-else-if="signa.template_id==6">
        <tr valign="top">
            <td>
                <div style="">
                    <table border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr valign="top">
                                <td>
                                    <div style="padding: 0px 0;border-radius: 0px;background-color: #fff;border:none;">
                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="left" style="text-align: left;width: 100%; max-width: 100%;">
                                            <tbody>

                                                <!-- first row -->
                                                <tr valign="top" >
                                                    <td style="border-bottom: solid 1px rgb(221, 221, 221);" >
                                                        <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="padding: 0px; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;">
                                                                        <div style="width: auto; max-width: 100%;">
                                                                            <p :style="'font-family: Arial, Helvetica, sans-serif; font-size: 15px; line-height: 18px; font-weight: 400; color:'+signa.color_nombre+'; white-space: normal; margin: 0px;'">{{nombre}}</p>
                                                                            <p style="font-family: Arial, Helvetica, sans-serif; font-size: 11px; line-height: 14px; white-space: normal; color:#828282; margin: 0px;">{{trabajo}}</p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr valign="top"><td height="10"><div style="height: 10px;"></div></td> </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr valign="top" >
                                                    <td>
                                                        <div style="width: 100%;">
                                                            <table border="0" cellspacing="0" cellpadding="0" width="100%" align="left" style="margin: 0px; border-collapse: collapse;text-align: left;width: 100%; max-width: 100%;">
                                                                <tbody>
                                                                    <tr valign="top"><td height="10"><div style="height: 10px;"></div></td> </tr>
                                                                    <tr > 
                                                                        <td v-if="active_profile"> <p style="margin: 1px;"><img :src="getAvatar" alt="" title="Profile Picture" width="65" height="65" style="display: inline-block; border: 0px; max-width: 65px; height: 65px;border-radius: 50%;"></p></td>
                                                                        <td v-if="active_profile" width="15"><div style="width: 15px;"></div></td>
                                                                        <td width="120" > 
                                                                            <table border="0" cellspacing="0" cellpadding="0" width="100%" align="left" style="margin: 0px;border-spacing: 0;border-collapse: collapse;text-align: left;width: 100%; max-width: 100%;">
                                                                                <tbody>
                                                                                    <tr><td><a :href="openLinckard()" target="_blank"><img :src="url_node+'/bc'+$i18n.locale+'/btn/'+signa.color_btn_principal.replace('#','')+'.png'" width="114" /></a></td></tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <table border="0" cellspacing="0" cellpadding="0" width="100%" align="left" style="border-top: 4px solid rgb(255,255,255);margin: 0px; border-spacing: 0;border-collapse: collapse;text-align: left;width: 100%; max-width: 100%;">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td  v-if="isWebsite" ><a  :href="openUrl(website)" target="_blank"><img :src="url_node+'/url/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                        <td  v-else-if="isCalendar" ><a  :href="openUrl(calendar)"  target="_blank"><img :src="url_node+'/calendar/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                        <td  v-else-if="isMobilephone" ><a  :href="openUrl('tel:'+mobilephone)"   target="_blank"><img :src="url_node+'/mobilephone/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                        <td  v-else-if="isWhatsapp" ><a  :href="openUrl(getWhatsappUrl)"  target="_blank"><img :src="url_node+'/whatsapp/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                        <td  v-else-if="isFixed" ><a   :href="openUrl('tel:'+phone)"  target="_blank"><img :src="url_node+'/phone/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                        <td  v-else-if="isEmail" ><a   :href="openUrl('mailto:'+email)"  target="_blank"><img :src="url_node+'/email/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                        
                                                                                        <td  v-if="isEmail" ><a   :href="openUrl('mailto:'+email)"  target="_blank"><img :src="url_node+'/email/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                        <td  v-else-if="isFixed" ><a   :href="openUrl('tel:'+phone)"  target="_blank"><img :src="url_node+'/phone/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                        <td  v-else-if="isMobilephone" ><a  :href="openUrl('tel:'+mobilephone)"   target="_blank"><img :src="url_node+'/mobilephone/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                        <td  v-else-if="isWhatsapp" ><a  :href="openUrl(getWhatsappUrl)"  target="_blank"><img :src="url_node+'/whatsapp/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                        <td  v-else-if="isCalendar" ><a  :href="openUrl(calendar)"  target="_blank"><img :src="url_node+'/calendar/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                        <td  v-else-if="isWebsite" ><a  :href="openUrl(website)" target="_blank"><img :src="url_node+'/url/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                        <td width="15"><div style="width: 15px;"></div></td>
                                                                        <td width="1" style="border-left: solid 1px #ddd;"><div style="width: 1px;height: 100%;"></div></td>
                                                                        <td width="15"><div style="width: 15px;"></div></td>
                                                                        <td>
                                                                            <table border="0" cellspacing="0" cellpadding="0">
                                                                                <tbody>
                                                                                    <tr valign="top">
                                                                                        <td>
                                                                                            <p style="margin: 1px;">
                                                                                                <img :src="getLogoBusinessFirma(preview)" @error="function(event){event.target.src=getLogoBusinessFirma(preview,null)}" alt="" title="Profile Picture" width="65" height="65" style="display: block; border: 0px; max-width: 65px; height: 65px;">
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr valign="top"><td height="10"><div style="height: 10px;"></div></td> </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign="top" >
                                                    <td style="border-top: solid 1px rgb(221, 221, 221);">
                                                        <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;">
                                                            <tbody>
                                                                <tr valign="top"><td height="10"><div style="height: 10px;"></div></td> </tr>
                                                                <tr valign="top">
                                                                    <td>
                                                                        <div style="padding:0px;">
                                                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="text-align: center;width: 100%; max-width: 100%;">
                                                                                <tbody>
                                                                                    <iconssocial :signature="signa" :linkcard="preview" parent="padding-top: 10px;" child="padding: 0 10px; text-align: center; padding-top: 0;" align="center"/>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr valign="top"><td height="10"><div style="height: 10px;"></div></td> </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <!-- /third row -->

                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                
                
                
                </div>
            </td>
        </tr>
</tbody>
<tbody v-else-if="signa.template_id==7">
        <tr valign="top">
            <td>
                <div style="">
                    <table border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr valign="top">
                                <td>
                                    <div style="">
                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="center" style="text-align: center;width: 100%; max-width: 100%;">
                                            <tbody>
                                                <tr v-if="active_profile" valign="top">
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="center" style="text-align: center;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top">
                                                                    <td>
                                                                        <p style="margin: 1px;">
                                                                            <img :src="getAvatar" alt="" title="Profile Picture" width="65" height="65" style="display: inline-block; border: 0px; max-width: 65px; height: 65px;border-radius: 50%;">
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr valign="top">
                                                                    <td height="10"><div style="height: 10px;"></div></td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr valign="top">
                                                    <td>
                                                        <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;width:auto;margin:0 auto;" >
                                                            <tbody>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;text-align:center;">
                                                                        <div style="width: auto; max-width: 100%;">
                                                                            <p :style="'font-family: Arial, Helvetica, sans-serif; text-align:center;font-size: 15px; line-height: 18px; font-weight: 400; color:'+signa.color_nombre+'; white-space: normal; margin: 0px;'">{{nombre}}</p>
                                                                            <p style="font-family: Arial, Helvetica, sans-serif;text-align:center; font-size: 11px; line-height: 14px; white-space: normal; color:#828282; margin: 0px;">{{trabajo}}</p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr valign="top"><td height="5"><div style="height: 5px;"></div></td> </tr>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;text-align:center;">
                                                                        <div style="width: auto; max-width: 100%;">
                                                                            <a style="margin-bottom:0px;display:block;" :href="openLinckard()" target="_blank"><img :src="url_node+'/bc'+$i18n.locale+'/btn/'+signa.color_btn_principal.replace('#','')+'.png'" width="114" /></a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr valign="top"><td height="5"><div style="height: 5px;"></div></td> </tr>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;text-align:center;">
                                                                        <div style="width: auto; max-width: 100%;">
                                                                            <table  cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;width:auto;margin:0 auto;" >
                                                                                <td  v-if="isWebsite" ><a :href="openUrl(website)" target="_blank"><img :src="url_node+'/url/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                <td  v-else-if="isCalendar" ><a :href="openUrl(calendar)"  target="_blank"><img :src="url_node+'/calendar/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                <td  v-else-if="isWhatsapp" ><a :href="openUrl(getWhatsappUrl)"  target="_blank"><img :src="url_node+'/whatsapp/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                <td  v-else-if="isMobilephone" ><a :href="openUrl('tel:'+mobilephone)"   target="_blank"><img :src="url_node+'/mobilephone/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                <td  v-else-if="isFixed" ><a  :href="openUrl('tel:'+phone)"  target="_blank"><img :src="url_node+'/phone/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                <td  v-else-if="isEmail" ><a  :href="openUrl('mailto:'+email)"  target="_blank"><img :src="url_node+'/email/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                              
                                                                                <td  v-if="isFixed" ><a  :href="openUrl('tel:'+phone)"  target="_blank"><img :src="url_node+'/phone/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                <td  v-else-if="isEmail" ><a  style="margin-display:block;" :href="openUrl('mailto:'+email)"  target="_blank"><img :src="url_node+'/email/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                <td  v-else-if="isMobilephone" ><a :href="openUrl('tel:'+mobilephone)"   target="_blank"><img :src="url_node+'/mobilephone/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                <td  v-else-if="isWhatsapp" ><a :href="openUrl(getWhatsappUrl)"  target="_blank"><img :src="url_node+'/whatsapp/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                <td  v-else-if="isCalendar" ><a :href="openUrl(calendar)"  target="_blank"><img :src="url_node+'/calendar/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                                <td  v-else-if="isWebsite" ><a :href="openUrl(website)" target="_blank"><img :src="url_node+'/url/btn/'+signa.color_btn_sec.replace('#','')+'.png'" width="55" /></a></td>
                                                                            </table>
                                                                        </div>
                                                                        
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>
                                                                                
                                                <tr valign="top" >
                                                    <td style="border-bottom: solid 1px rgb(221, 221, 221);">
                                                        <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;width:auto;margin:0 auto;">
                                                            <tbody>
                                                                <tr valign="top" >
                                                                    <td style="font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;">
                                                                        <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="text-align: center;width: 100%; max-width: 100%;">
                                                                            <tbody>
                                                                                <iconssocial :signature="signa" :linkcard="preview" parent="padding-top: 10px;" child="padding: 0 10px; text-align: center; padding-top: 0;" align="left"/>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>

                                                <tr valign="top">
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="center" style="text-align: center;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top">
                                                                    <td>
                                                                        <p style="margin: 1px;">
                                                                            <img :src="getLogoBusinessFirma(preview,null)" alt="" title="Profile Picture"  height="auto" style="display: inline-block; border: 0px; max-width: 180px;" :style="'width:'+(getUser.signature.logo_width)+'px'" >
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <!-- /third row -->



                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </td>
        </tr>
</tbody>
<tbody v-else-if="signa.template_id==8">
        <tr valign="top">
            <td>
                <div style="">
                    <table border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr valign="top">
                                <td>
                                    <div style="padding: 15px 0;border-radius: 0px;background-color: #fff;border:none;">
                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="right" style="text-align: right;width: 100%; max-width: 100%;">
                                            <tbody>
                                                <tr v-if="active_profile" valign="top">
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="right" style="text-align: right;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top">
                                                                    <td><p style="margin: 1px;"><img :src="getAvatar" alt="" title="Profile Picture" width="65" height="65" style="display: inline-block; border: 0px; max-width: 65px; height: 65px;border-radius: 50%;"></p></td>
                                                                </tr>
                                                                <tr valign="top"><td height="10"><div style="height: 10px;"></div></td> </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr valign="top">
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="right" style="text-align: right;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;">
                                                                        <div style="width: auto; max-width: 100%;">
                                                                            <p :style="'font-family: Arial, Helvetica, sans-serif; font-size: 15px; line-height: 18px; font-weight: 400; color:'+signa.color_nombre+'; white-space: normal; margin: 0px;'">{{nombre}}</p>
                                                                            <p style="font-family: Arial, Helvetica, sans-serif; font-size: 11px; line-height: 14px; white-space: normal; color:#828282; margin: 0px;">{{trabajo}}</p>
                                                                        </div>    
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr valign="top">
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="right" style="text-align: right;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top"><td height="5"><div height="5" valign="top" style="font-size:0px;line-height:0px;height:5px;">&nbsp;</div></td> </tr>
                                                                <tr valign="top" >
                                                                    <td style="padding:0px;; font-family: Arial, Helvetica, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap;">
                                                                        <div style="width: auto; max-width: 100%;">
                                                                            <a style="margin-bottom:0px;display:block;" :href="openLinckard()" target="_blank"><img :src="url_node+'/bc'+$i18n.locale+'/btn/'+signa.color_btn_principal.replace('#','')+'.png'" width="114" /></a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>     
                                                <tr valign="top" style="border-bottom: solid 1px rgb(221, 221, 221);">
                                                    <td style="border-bottom: solid 1px rgb(221, 221, 221);">
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="right" style="text-align: right;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top" >
                                                                    <td>   
                                                                        <div style="padding: 0px 0px 0px;">
                                                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" align="right" style="text-align: right;width: 100%; max-width: 100%;">
                                                                                <tbody>
                                                                                    <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>
                                                                                    <iconssocial :signature="signa" :linkcard="preview" parent="" child="padding-right: 10px; text-align: center; padding-top: 0;" align="right"/>
                                                                                    <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr valign="top" style="border-top: solid 1px rgb(221, 221, 221);">
                                                    <td>
                                                        <table border="0" cellspacing="0" cellpadding="0" width="100%" align="center" style="text-align: right;width: 100%; max-width: 100%;">
                                                            <tbody>
                                                                <tr valign="top"><td height="10"><div style="height: 10px;"></div></td></tr>
                                                                <tr valign="top"><td> <p style="margin: 1px;"><img :src="getLogoBusinessFirma(preview,null)" alt="" title="Profile Picture" width="auto" height="65" style="display: inline-block; border: 0px; max-width: 180px; max-height: 65px;"> </p></td></tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                
                
                
                </div>
            </td>
        </tr>
</tbody>
</template>

<script>
import iconssocial from "@/layouts/signature_Customizer/iconssocial.vue"
export default {
    props:['signature','linkcard','profile_photo','profile'],
    data() {
        return {
            getUser:this.profile,
            signa:this.signature,
            active_profile:this.profile_photo || this.$router.history.current.name.includes('signatures'),
            url_node:'https://api.linkcard.app/api/signature',
            //url_node:'http://localhost:5001/linckard-1545486188489/us-central1',
            preview:this.linkcard
        }
    },
    mounted(){
        console.log(this.signa)
    },
    methods: {

        getLogoBusinessFirma(element,size='111x111'){
            let profile=this.getUser
            let img=element && element.style && element.style.firma_new?element.style.firma_new.img:null
            if(!img)img=profile.signature && profile.signature.img?profile.signature.img:null
            if(!img && profile.signature.img && profile.signature.img.obj)return profile.signature.img.obj
            else if(!img && profile.signature.img)return profile.signature.img
            else if(!img)return ('https://firebasestorage.googleapis.com/v0/b/linckard-1545486188489.appspot.com/o/Your_Logo_Signature.png?alt=media');
            else if(img && img.obj)return img.obj;
            else if(img)return this.$util.removeToken(img,size)
        },
        getLincImage_error(event) {
            console.log(event)
            event.target.src = this.getLogoBusinessFirma()
        },
        getUrl(){
            if(process.client)return window.location
        },
        openLinckard() {
            let id=this.preview?this.preview.id:0
            return this.$util.cardURL()+this.getUser.username+'/'+id+'?source=email'
        },

        openUrl(url) {
            if(typeof url == 'string' && url.includes('mailto:')){
                 url+='?subject='+this.$t('general.whatsmsg')
            }
            return url
        },
    },
    computed:{
        getAvatar(){
            let img=this.preview?this.preview.info.img:null
            if(this.preview && this.preview.sobredrags && this.preview.sobredrags[0] && this.preview.sobredrags[0].compo=='editAvatar' && this.preview.sobredrags[0].img!=null)return this.preview.sobredrags[0].img
            else if(!img)return 'https://firebasestorage.googleapis.com/v0/b/linckard-1545486188489.appspot.com/o/firma_user.png?alt=media'
            else if(img && img.obj)return img.obj;
            else if(img && this.preview && this.preview.info.img){
                if(this.preview.info.img && this.preview.info.img_round)img=this.preview.info.img
                return img
            }
            else if(img)return this.$util.removeToken(img)
        },
        nombre(){
            if(!this.preview)return "Elon Musk"
            return this.preview.info.nombre
        },
        trabajo(){
            if(!this.preview)return "Technoking of Tesla"
            return this.preview.info.trabajo
        },
        whatsapp(){
            if(!this.preview)return "+54 9 121 121212 1"
            return this.preview.info.mobilephone
        },
        email(){
            if(!this.preview)return "info@linkcard.app"
            return this.preview.info.email
        },
        mobilephone(){
            if(!this.preview)return "+54911 4545 4545"
            return this.preview.info.mobilephone
        },
        phone(){
            if(!this.preview)return "+54 9 121 121212 1"
            return this.preview.info.phone
        },
        website(){
            if(!this.preview)return "https://www.linkcard.app"
            return this.preview.info.website
        },
        calendar(){
            if(!this.preview)return "/mycalendar"
            return this.preview.info.calendar
        },
        isMobilephone (){
            if(!this.preview)return false
            if(this.preview.info.mobilephone)return true
            if(!this.preview.info.clayout)this.preview.info.clayout= 1
            var lay=this.preview.info.clayout;
            return lay==1 || lay==2 || lay==5 || lay==12;
        },
        isFixed(){
            if(!this.preview)return false
            if(this.preview.info.phone)return true
            var lay=this.preview.info.clayout;
            return lay==3 || lay==4 || lay==6;
        },
        isWhatsapp(){
            if(this.getUser.username=='trademarkia')return false
            if(this.getUser.username=='nordec')return false
            return this.isWhatsappTop || this.isWhatsappBottom
        },
        isWhatsappTop(){
            if(!this.preview)return true
            var lay=this.preview.info.clayout;
            return lay==9;
        },
        isWhatsappBottom(){
            if(!this.preview)return false
            var lay=this.preview.info.clayout;
            return lay==1 || lay==4 || lay==10;
        },
        getWhatsappUrl(){
            if(!this.preview)return false
            if(!this.preview.info.mobilephone || this.preview.info.mobilephone === '')return;
            let wp=this.preview.info.mobilephone + '';
            wp = wp.replace(/ /g, "")
            wp = wp.replace('+', "")
            return 'https://api.whatsapp.com/send?text='+this.$t('general.whatsmsg')+'&phone='+wp;
        },
        isEmail(){
            return this.isEmailTop || this.isEmailBottom
        },
        isEmailTop(){
            if(!this.preview)return true
            var lay=this.preview.info.clayout;
            return lay==7;
        },
        isEmailBottom(){
            if(!this.preview)return false
            var lay=this.preview.info.clayout;
            return lay==1 || lay==2 || lay==3 || lay==4 || lay==5 || lay==6 || lay==8 || lay==9 ||  lay==11 ;
        },
        isWebsite(){
            return this.isWebsiteTop || this.isWebsiteBottom
        },
        isWebsiteTop(){
            if(!this.preview)return true
            var lay=this.preview.info.clayout;
            return lay==8;
        },
        isWebsiteBottom(){
            if(!this.preview)return true
            var lay=this.preview.info.clayout;
            return lay==7;
        },
        isSms(){
            if(!this.preview)return false
            var lay=this.preview.info.clayout;
            return lay==2 || lay==3;
        },
        is3layout(){
            if(!this.preview)return false
            if(!this.preview)return false
            var lay=this.preview.info.clayout;
            return lay<5;
        },
        isCalendar(){
            if(!this.preview)return true
            return this.preview.info.calendar !== undefined && this.preview.info.calendar !== ''
        },
    },
    components:{
        iconssocial
    }
}
</script>