<template>
  <modal
    name="modal-firma"
    transition="nice-modal-fade"
    :min-height="450"
    :pivot-y="0.5"
    :adaptive="true"
    :scrollable="true"
    :reset="true"
    :width="$util.hasSignaBanner(getUser.signature) ? 600 : 500"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="modal_wrapper card_share_dropdown account panel_bc ">
      <div slot="top-right">
        <button
          @click="$modal.hide('modal-firma')"
          class="ngdialog-close"
        ></button>
      </div>
      <div class="card container pt10">
        <div v-if="edit" class="edicion_block">
          <h4 class="text-center mb10">{{ $t("firma.titp") }}</h4>
        </div>
        <div class="edicion_block w100">
          <!-- switch "redondear" -->
          <div class="switch_wrapper mb15">
            <label
              class="d_inlineb t_11px mr5"
              style="position: relative; top: 2px;"
              >{{ $t("firma.ocultar") }} {{ getUser.signature.template_id }}</label
            >
            <div
              v-if="!loading"
              class="d_inlineb pos_relative pr20 switch_container"
            >
              <label class="switch mb-0">
                <input
                  type="checkbox"
                  @change="activar($event)"
                  v-model="active"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <font-awesome-icon v-else icon="circle-notch" spin />
          </div>
        </div>
        <div class="edicion_block previewfirma" style="padding:0px !important;">
          <div
            id="firma_wrapper"
            bgcolor="#fff"
            :class="
              $util.hasSignaBanner(getUser.signature)
                ? 'conbanner'
                : 'sinbanner'
            "
          >
            <table
              id="firma_wrapper_tables"
              border="0"
              cellspacing="0"
              cellpadding="0"
            >
              <templates
                :profile="getUser"
                :signature="getUser.signature"
                :linkcard="cardVal"
                :profile_photo="active"
                :key="active"
              />
              <tfoot
                v-if="$util.hasSignaPartner(cardVal.info)"
                class="pvw_banner"
                style="display:block;margin-top:10px;"
              >
                <tr valign="top" style="">
                  <td v-if="cardVal.info.xai">
                    <a
                      target="_blank"
                      style="display:block;"
                      :href="$util.sanitizar(cardVal.info.xai, 'xai')"
                      ><img
                        src="@/assets/images/signa_partners/xai.gif"
                        width="145px"
                        alt="signature_banner"
                    /></a>
                  </td>
                  <td v-if="cardVal.info.xai" width="15">
                    <div style="width: 15px;"></div>
                  </td>
                  <td v-if="cardVal.info.whereby">
                    <a
                      target="_blank"
                      style="display:block;"
                      :href="$util.sanitizar(cardVal.info.whereby, 'whereby')"
                      ><img
                        src="@/assets/images/signa_partners/whereby.gif"
                        width="145px"
                        alt="signature_banner"
                    /></a>
                  </td>
                  <td v-if="cardVal.info.whereby" width="15">
                    <div style="width: 15px;"></div>
                  </td>
                  <td v-if="cardVal.info.calendarcom" style="">
                    <a
                      target="_blank"
                      style="display:block;"
                      :href="
                        $util.sanitizar(cardVal.info.calendarcom, 'calendarcom')
                      "
                      >
                      <img
                        src="@/assets/images/signa_partners/calendarcom.gif"
                        width="145px"
                        alt="signature_banner"
                    /></a>
                  </td>
                  <td v-if="cardVal.info.calendarcom" width="15">
                    <div style="width: 15px;"></div>
                  </td>
                </tr>
              </tfoot>
              <tfoot
                v-if="$util.hasSignaBanner(getUser.signature)"
                class="pvw_banner"
                style="display:block;margin-top:20px;"
              >
                <tr valign="top" style="">
                  <td style="">
                    <a
                      target="_blank"
                      style="display:block;"
                      :href="$util.sanitizar(getUser.signature.url)"
                      ><img
                        :src="$util.getSignaBanner(getUser.signature)"
                        :style="
                          'width:' + getUser.signature.banner.width + 'px'
                        "
                        :width="getUser.signature.banner.width"
                        alt="signature_banner"
                    /></a>
                  </td>
                </tr>
              </tfoot>
              <tfoot
                v-if="getUser.signature.desc"
                class="pvw_banner"
                style="display:block;margin-top:5px;max-width:600px;"
              >
                <tr valign="top">
                  <td>
                    <p style="display:block;font-size:10px;margin-top:10px;">
                      {{ getUser.signature.desc }}
                    </p>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div
          v-if="!preview.isPreview"
          class="btn_container mt20 mb10 text-center  d-flex justify-content-around "
        >
          <imagePicker
            imgid="email"
            v-model="preview.img"
            :isCrop="true"
            :aspect="true"
          />
          <button
            v-if="!showUploadButton && !loading"
            type="button"
            class="btn btn-info btn-initial ml5 mr5"
            @click="doCopy"
          >
            <img src="@/assets/images/icon_duplicate_white.svg" width="16" />
            {{ $t("firma.copiar") }}
          </button>
          <button
            type="button"
            v-else-if="showUploadButton && !loading"
            @click="subirImg()"
            class="btn btn-info btn-initial ml5 mr5"
          >
            <font-awesome-icon icon="upload" /> {{ $t("general.guardar") }}
          </button>
          <button
            type="button"
            v-else-if="loading"
            class="btn btn-info btn-initial ml5 mr5"
          >
            <font-awesome-icon icon="circle-notch" spin />
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import switchPicker from "@/layouts/main_Customizer/dinputs/switchPicker";
import templates from "@/layouts/signature_Customizer/templates.vue";
import { db } from "@/services/firebaseConfig.js";
import colorPicker from "@/layouts/main_Customizer/dinputs/colorPicker";
import sizePicker from "@/layouts/main_Customizer/dinputs/sizePicker";
import imagePicker from "@/components/imagePicker";
import { isEmptyObject } from "jquery";
import * as clipboard from "clipboard-polyfill/text";

export default {
  data() {
    return {
      getUser: this.$store.state.userProfile,
      edit: true,
      status: "",
      active: true,
      loading: false,
      clayouts: [{ id: 1 }, { id: 2 }],
      layout: 1,
      cardVal: {
        info: {},
        style: {
          firma_new: {},
        },
      },
    };
  },
  methods: {
    beforeOpen(event) {
      this.cardVal = JSON.parse(JSON.stringify(event.params.linkcard));
      //this.active=this.cardVal.style.firma_new.active
    },
    async subirImg() {
      var dis = this;
      this.loading = true;
      this.cardVal = await this.$util.uploadImages(this.cardVal);

      let index = this.getUser.linckards.findIndex(function(i) {
        return i.id == dis.cardVal.id;
      });
      if (index >= 0) this.getUser.linckards[index] = this.cardVal;
      if (index >= 0)
        this.$store.state.userProfile.linckards[
          index
        ].style.firma_new.img = this.cardVal.style.firma_new.img;

      await this.$util.guardarLincsGeneralEnterprise(dis.getUser.linckards);
      this.loading = false;
    },

    doCopy() {
      var dis = this;
      let html = document.getElementById("firma_wrapper_tables").outerHTML;
      html = html.replaceAll("&nbsp;", "");
      const item = new window.ClipboardItem({
        "text/html": new Blob([html], { type: "text/html" }),
        "text/plain": new Blob([html], { type: "text/plain" }),
      });
      navigator.clipboard.write([item]);
      dis.$util.notif_success(dis.$t("notis.copyemail"));
    },
    getAvatar() {
      let img = this.cardVal.info.img;
      if (!img)
        return "https://firebasestorage.googleapis.com/v0/b/linckard-1545486188489.appspot.com/o/firma_user.png?alt=media";
      else if (img && img.obj) return img.obj;
      else if (img) {
        if (this.cardVal.info.img_round)
          img = this.linckard.info.img.replace(
            "?alt=media",
            "_round?alt=media"
          );
        return img;
      }
    },
    async activar() {
      let dis = this;
      dis.loading = true;
      this.cardVal.style.firma_new.active = !this.cardVal.style.firma_new
        .active;
      let val = this.cardVal.style.firma_new.active;
      var elindex = this.getUser.linckards.findIndex(function(i) {
        return i.id == dis.cardVal.id;
      });
      if (!this.getUser.linckards[elindex].style.firma_new)
        this.getUser.linckards[elindex].style.firma_new = {};
      this.getUser.linckards[elindex].style.firma_new.active = val;
      this.active = val;

      await this.$util.guardarLincsGeneralEnterprise(dis.getUser.linckards);
      //this.$modal.hide('modal-firma')
      dis.loading = false;
      dis.$util.notif_success();
    },
  },
  computed: {
    showUploadButton() {
      console.log(
        !isEmptyObject(this.cardVal.style.firma_new.img),
        typeof this.cardVal.style.firma_new.img == "object"
      );
      return (
        !isEmptyObject(this.cardVal.style.firma_new.img) &&
        typeof this.cardVal.style.firma_new.img == "object"
      );
    },
    preview() {
      return this.cardVal.style ? this.cardVal.style.firma_new : {};
    },
    info() {
      return this.cardVal.info;
    },
  },
  watch: {},
  components: {
    colorPicker: colorPicker,
    sizePicker: sizePicker,
    imagePicker: imagePicker,
    templates,
    switchPicker,
  },
};
</script>

<style>
.radiobutton_group {
  padding-top: 10px !important;
}

#firma_wrapper.sinbanner {
  display: flex;
  justify-content: center;
}
#firma_wrapper.conbanner {
  display: flex;
  justify-content: left;
}
@media (max-width: 1800px) {
  #rdFirmas #radio3,
  #rdFirmas #radio4 {
    width: 67px;
  }
}
@media (max-width: 1600px) {
  #rdFirmas #radio3,
  #rdFirmas #radio4 {
    width: 57px;
  }
}
.previewfirma {
  padding: 0px;
}
.previewfirma > .info_text {
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
}
</style>
