<template>
    <div style="width: 100%;">
        <profile :user="user" />
        <ul class="primary_menu" style="margin-bottom: 0px;">
            <entry text="Dashboard" icon="ph:chalkboard-teacher-thin" route="/dashboard" />

            <folder @click.native="folderActive = folderActive === 1 ? null : 1" items=4 :open="folderActive === 1" text="Business Cards"
                icon="ph:user-list-light">
                <entry text="Folders" icon="ph:folder" route="/sector/sectors/bc" />
                <!--entry text="Templates" icon="ph:identification-badge-light" route="/templates/bc" /-->
                <entry text="Email Signatures" icon="ph:signature-light" route="/signatures" />
                <entry text="Leads" icon="ph:users-three-light" route="/leads/bc"/>
                <entry text="Stats" icon="ph:chart-line-light" route="/stats/landings/bc"/>
            </folder>
            <folder @click.native="folderActive = folderActive === 2 ? null : 2" items=3 :open="folderActive === 2" text="Micro Landings"
                icon="ph:megaphone-thin">
                <entry text="Folders" icon="ph:folder" route="/sector/sectors/pas" />
                <entry text="Leads" icon="ph:users-three-light" route="/leads/pas"/>
                <entry text="Stats" icon="ph:chart-line-light" route="/stats/landings/pas"/>
                <!--entry text="Templates" icon="ph:identification-badge-light" route="/templates/pas"/>
                <entry text="Leads" icon="ph:users-three-light" />
                <entry text="Stats" icon="ph:chart-line-light" /-->
            </folder>
            <folder @click.native="folderActive = folderActive === 6 ? null : 6" items=3 :open="folderActive === 6" text="Link-in-Bio"
                icon="ph:link-light">
                <entry text="Folders" icon="ph:folder" route="/sector/sectors/lib" />
                <entry text="Leads" icon="ph:users-three-light" route="/leads/lib"/>
                <entry text="Stats" icon="ph:chart-line-light" route="/stats/landings/lib"/>
                <!--entry text="Templates" icon="ph:identification-badge-light" route="/templates/lib"/>
                <entry text="Leads" icon="ph:users-three-light" />
                <entry text="Stats" icon="ph:chart-line-light" /-->
            </folder>
            <!--folder @click.native="folderActive = 3" items=1 :open="folderActive === 3" text="Events"
                icon="ph:calendar-check-light">
                <entry text="Folders" icon="ph:magic-wand-light" route="/sector/sectors/evnt" />
                <entry text="Templates" icon="ph:identification-badge-light" route="/templates/evnt"/>
                <entry text="Leads" icon="ph:users-three-light" />
                <entry text="Stats" icon="ph:chart-line-light" />
            </folder-->
            <folder @click.native="folderActive = folderActive === 4 ? null : 4" items=2
                :open="folderActive === 4" text="Loyalty" icon="ph:trophy-light">
                <entry text="Folders" icon="ph:folder" route="/loyalty/loyaltys" />
                <entry text="Leads" icon="ph:users-three-light" route="/leads/loyaltys"/>
                <!--entry text="Members" icon="ph:users-three-light" />
                                <entry text="Stats" icon="ph:chart-line-light" /-->
            </folder>
            <folder v-if="$util.isFounder(getUser)" @click.native="folderActive = 5" items=2 :open="folderActive === 5" text="Superuser"
                icon="ph:gear-six-light">
                <entry text="Admin" icon="ph:gear-light" route="/admin" />
                <entry text="Directory" icon="ph:user-list-light" route="/directory" />
            </folder>
        </ul>
        <ul class="primary_menu" style="margin-top: 0px; margin-bottom: 0px;">
                    <li
                        :class="{ active: $router.history.current.name === 'account' || $router.history.current.name === 'account' }">
                        <a style="border:0px;" @click="toggleRoute('/account#profile')">
                            <Icon icon="ph:user-circle-light" style="font-size: 18px;" />
                            {{ $t('leftnav.micuenta') }}
                        </a>
                    </li>
                </ul>
        <ul class="secondary_menu" style="margin-top: 10px;">
            <li @click="salir();"><a>{{ $t('general.logout') }}</a></li>
        </ul>
    </div>
</template>

<script>
import profile from "@/components/menu/profile.vue"
import folder from "@/components/menu/folder.vue"
import entry from "@/components/menu/entry.vue"
import slider from "@/components/external/slider.vue"

export default {
    props: ['user'],
    data() {
        return {
            active: true,
            folderActive: null,
        }
    },
    mounted() {
        if(this.$router.history.current.path.includes("/sector/sectors/evnt")) this.folderActive = 3
        if(this.$router.history.current.path.includes("/sector/sectors/lib")) this.folderActive = 6
        if(this.$router.history.current.path.includes("/sector/sectors/pas")) this.folderActive = 2
        if(this.$router.history.current.path.includes("/sector/sectors/bc")) this.folderActive = 1
        if(this.$router.history.current.path.includes("/leads/bc")) this.folderActive = 1
        if(this.$router.history.current.path.includes("/leads/pas")) this.folderActive = 2
        if(this.$router.history.current.path.includes("/leads/lib")) this.folderActive = 6
        if(this.$router.history.current.path.includes("/leads/loyaltys")) this.folderActive = 4
        if(this.$router.history.current.path.includes("/stats/landings/bc")) this.folderActive = 1
        if(this.$router.history.current.path.includes("/stats/landings/pas")) this.folderActive = 2
        if(this.$router.history.current.path.includes("/stats/landings/lib")) this.folderActive = 6
        if(["/templates/pas"].includes(this.$router.history.current.path)) this.folderActive = 2
        if(["/templates/lib"].includes(this.$router.history.current.path)) this.folderActive = 6
        if(["/templates/evnt"].includes(this.$router.history.current.path)) this.folderActive = 3
        if(["/loyalty/loyaltys"].includes(this.$router.history.current.path)) this.folderActive = 4
        if(["/admin"].includes(this.$router.history.current.path)) this.folderActive = 5
        if(["/directory"].includes(this.$router.history.current.path)) this.folderActive = 5
        if(["/sector/sectors/pas"].includes(this.$router.history.current.path)) this.folderActive = 2
        if(["/sector/sectors/lib"].includes(this.$router.history.current.path)) this.folderActive = 6
        if(["/sector/sectors/evnt"].includes(this.$router.history.current.path)) this.folderActive = 3
    },
    methods: {
        toggleRoute(route) {
            this.$router.push(route)
            this.$util.closeAllDivs()
        },
        salir() {
            var dis = this;
            if (this.$store.getters.hasBeenEdited) {
                this.$modal.show('dialog', {
                    title: dis.$t('notis.atencion'), text: dis.$t('notis.seguroque'),
                    buttons: [
                        { title: dis.$t('notis.si'), handler: () => { this.$util.logout() } },
                        { title: dis.$t('general.no') }
                    ]
                })
            } else this.$util.logout()
        },
    },
    components: {
        profile,
        folder,
        entry,
        slider
    }
}
</script>