
<template>
  <modal name="modal-crop" transition="nice-modal-fade" classes="ngdialog-content" :pivot-y="0.5" :clickToClose="false"
    :adaptive="true" :scrollable="true" :zoomable='true' :reset="true" width="480" height="auto" @before-open="beforeOpen"
    @before-close="beforeClose">
    <div class="modal_wrapper">
      <div class="d-flex justify-content-between align-items-center" slot="top-right">
        <h6 style="color:#C43BA7;">{{ $t('pickers.editori') }}</h6>
        <button @click="$modal.hide('modal-crop')" class="ngdialog-close"></button>
      </div>

      <div class="d-flex align-items-center justify-content-center mb-3" :class="circle">
        <vue-cropper ref='cropper' dragMode="move" :cropBoxMovable="false" preview=".preview" :modal="false"
          :guides="false" :rotatable="true" :scalable="false" :src="imgSrc" :cropBoxResizable="!isCircle()"
          :aspectRatio="aspect" alt="Source Image" :img-style="{ 'width': '300px', 'height': '300px' }">
        </vue-cropper>
            &nbsp;&nbsp;&nbsp;
        <div v-if="!$util.isMobile()" class="preview"></div>
      </div>

      <div>
        <div class="d-flex align-items-center justify-content-center mb-3">
          <vs-button-group>
            <vs-button flat icon @click="$refs.cropper.relativeZoom(-0.1)">
              <Icon icon="ph:magnifying-glass-minus-light" style="font-size: 20px;" />
            </vs-button>
            <vs-button flat icon @click="$refs.cropper.relativeZoom(0.1)">
              <Icon icon="ph:magnifying-glass-plus-light" style="font-size: 20px;" />
            </vs-button>
            <vs-button flat icon @click="$refs.cropper.rotate(-90)">
              <Icon icon="ph:arrow-counter-clockwise-light" style="font-size: 20px;" />
            </vs-button>
            <vs-button flat icon @click="$refs.cropper.rotate(90)">
              <Icon icon="ph:arrow-clockwise-light" style="font-size: 20px;" />
            </vs-button>

            <!--vs-button flat icon @click="toSquare()">
              <Icon icon="ph:rectangle-light" style="font-size: 20px;" />
            </vs-button>
            <vs-button flat icon @click="toCircle()">
              <Icon icon="ph:circle-light" style="font-size: 20px;" />
            </vs-button>

            <vs-button flat icon @click="$refs.cropper.scale(1, -1)">
              <Icon icon="ph:arrows-vertical-light" style="font-size: 20px;" />
            </vs-button>
            <vs-button flat icon @click="$refs.cropper.scale(-1,-1)">
              <Icon icon="ph:arrows-horizontal-light" style="font-size: 20px;" />
            </vs-button-->

            <vs-button flat @click="saveImage">
              <Icon icon="ph:floppy-disk-light" style="font-size: 20px;" /> &nbsp;
              {{ $t('general.guardar') }}
            </vs-button>
          </vs-button-group>
        </div>
      </div>
    </div>
  </modal>
</template>




<script>
import { Icon } from "@iconify/vue2"

export default {
  data() {
    return {
      imgSrc: '',
      circle: '',
      aspect: 0,
      parent: null,
    };
  },
  components: {
    Icon
  },
  methods: {
    toCircle() {
      this.circle = 'circle';
    },
    toSquare(){
      this.circle = ''
    },
    isCircle() {
      return this.circle == 'circle'
    },
    async beforeOpen(event) {
      this.parent = event.params.parent;
      
      // convertir a blob
      if (typeof this.parent.blobImage === 'string' || !this.parent.blobImage) {
        this.fetchImageAsBlob(this.parent.image).then(blob => {
          this.setImage(blob);
        });
      } else {
        this.imgSrc = this.setImage(this.parent.blobImage);
      }

      if (this.parent.circle) this.circle = "circle"
      else this.circle = ''
      
      if (this.parent.theAspect == 1) {
        this.aspect = this.parent.theAspect
      } else {
        this.aspect = 0
      }
    },

    async fetchImageAsBlob(imageUrl) {
      if (typeof imageUrl === 'object' && imageUrl.default) {
        imageUrl = imageUrl.default;
      }
      
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        return blob;
      } catch (error) {
        console.error('Error fetching image:', error);
        return null;
      }
    },

    beforeClose() {
      this.imgSrc = ''
    },

    setImage(file) {
      return new Promise((resolve, reject) => {      
        if (!file) {
          console.log('No file provided:', file);
          return;
        }

        if (file instanceof Blob || file instanceof File) {
          if (!file.type.includes('image/')) {
            console.error('Invalid image file type');
            return;
          }
        } else if (typeof file === 'object') {
          file = file.file;
        }

        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.imgSrc = event.target.result;
            if (this.$refs.cropper) {
              this.$refs.cropper.replace(event.target.result);
            }
          };
          reader.readAsDataURL(file);
        } else {
          console.error('FileReader API not supported');
        }
        console.error('Invalid file type:', file);
        reject();
      });
    },

    getRoundedCanvas(sourceCanvas) {
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
      var width = sourceCanvas.width;
      var height = sourceCanvas.height;
      canvas.width = width;
      canvas.height = height;
      context.imageSmoothingEnabled = true;
      context.drawImage(sourceCanvas, 0, 0, width, height);
      context.globalCompositeOperation = 'destination-in';
      context.beginPath();
      context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
      context.fill();
      return canvas;
    },

    async saveImage() {
      var dis = this;
      dis.$modal.hide('modal-crop')
      // get image data for post processing, e.g. upload or setting image src
      let toBlob = this.$refs.cropper.getCroppedCanvas();
      let toBlobRound = null

      if (this.isCircle() || true) {
        toBlobRound = this.getRoundedCanvas(toBlob)
        toBlobRound = await new Promise(resolve => toBlobRound.toBlob(resolve));
      }

      toBlob.toBlob((blob) => {
        let cropImg = toBlob.toDataURL('image/png');
        dis.parent.image = cropImg

        let obj = {
          obj: cropImg,
          file: blob,
          file_round: toBlobRound,
          imgid: dis.parent.imgid,
          ext: dis.parent.ext,
          file_type: dis.parent.blobImage
        }
        dis.parent.$emit('input', obj);
        dis.$util.pushToBus(obj)
      });

    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.circle .cropper-crop-box,
.circle .cropper-view-box {
  border-radius: 50% !important;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.preview {
  margin: 0 auto;
  overflow: hidden;
  width: 200px;
  height: 200px;
  width: 150px;
  height: 150px;
  border: 0px;

}

.circle .preview {
  border-radius: 50% !important;
}
</style>