<template>		
<div class="mb-3 form-group">
	<label v-if="labels" style="display:block">{{labels}}</label>
	<p v-if="image" style="max-width:250px !important;margin:0px;word-wrap: break-word;"></p>
	<label v-if="!image"  :class="'btn btn-primary btn-initial manito '+clase">
		<span style="color:white;">{{blabel}}</span>
		<input :id="$util.randomchar()" type="file" @change="onFileChange"  accept="image/*" />
	</label>
	<button type="button" v-else @click="removeImage()" class="btn btn-primary btn-initial manito" style="background:#C9C9C9;color:white;"><font-awesome-icon icon="image"/> {{$t('pickers.cambiarn')}}</button>
	<button type="button" v-if="image && !String(image).includes('storage')" @click="cropImage"  class="btn btn-primary btn-initial manito" style="color:white;"><font-awesome-icon icon="edit"/>  {{$t('general.editar')}}</button>

</div>
</template>


<script>


export default {
	props:['value','isCircle','isCrop','label','imgid','aspect','btext','classe'],
	data(){
		return{
			theAspect:this.aspect,
			clase:this.classe,
			blabel:this.btext?this.btext:this.$t('pickers.load'),
			circle:this.isCircle?true:false,
			crop:this.isCrop?true:false,
			getUser:this.$store.state.userProfile,
			ext:'',
			image:this.value,
			blobImage:null,
			labels:this.label
		}
	},
	mounted() {
		if (this.image && (typeof this.image === 'string' || this.image.default)) {
			const imageUrl = this.image.default || this.image;
			fetch(imageUrl)
			.then(response => response.blob())
			.then(blob => {
				this.blobImage = blob;
			})
			.catch(error => console.error('Error loading initial image:', error));
		}
	},
	methods:{
        async onFileChange(e) {
			var dis=this;
			var files = e.target.files || e.dataTransfer.files;
			if (!files.length)
				return;
			var file=files[0];
			var sizemb=file.size/1024/1024;
			if(sizemb>6){
				dis.$util.notif_error(dis.$t('notis.tamanio'))
				return
			}
			this.ext=file.name.split('.').pop().toLowerCase()
			if(this.ext == 'jpg' || this.ext == 'jpeg' || this.ext == 'gif' || this.ext == 'bmp' || this.ext == 'png'){
				
			}else if((this.ext == 'webp' || this.ext == 'svg') && this.$util.isFounder(this.getUser) ){
				
			}else{
				this.$util.notif_error(dis.$t('notis.formato'))
				return;
			}

			this.image=file;
			this.blobImage=file;
			
			if(this.crop && this.ext!='gif')
				this.cropImage();
			
			this.createImage(file)	
		},
		cropImage(){
			var dis=this;
			//Esto es para abrir el editor mas tarde
			let gettedBUs=this.$util.getfromBus(dis.image.id);
			if(gettedBUs && !this.blobImage)this.blobImage=gettedBUs
			this.circle=this.isCircle?true:false
			this.crop=this.isCrop?true:false
			this.$modal.show('modal-crop',{parent:dis});
		},
        createImage(file) {
			var reader = new FileReader();
			var dis = this;
			reader.onload = async (e) => {
				const obj = {
					obj: e.target.result,
					file: file,
					imgid: dis.imgid,
					ext: dis.ext,
					file_type: dis.blobImage
				};
				
				if (dis.imgid === 'seo') {
					const url = await dis.$util.uploadAndGetUrl(obj);
					dis.$emit('input', url);
				} else {
					dis.$emit('input', obj);
					dis.$util.pushToBus(obj);
				}
			};
			reader.readAsDataURL(file);
		},
        removeImage() {
			var dis=this;
			this.$modal.show('dialog', {title: dis.$t('notis.atencion'),text: dis.$t('general.estaseguro2'),
            buttons: [
                {title: dis.$t('notis.si'), handler: () => { 
					dis.image = null;
					dis.$emit('input', dis.image); 
					dis.$modal.hide('dialog')
				}},
                {title: dis.$t('general.no')}
            ]
          	})
			
        }

	},
}
</script>
<style scoped>

.imageboton {
    background: #3F5B5A !important;
    width:100% !important;
}
</style>
