import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _34e0c5aa = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _7bcb19e8 = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _760136a6 = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _6e51555e = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _160cf4aa = () => interopDefault(import('../pages/directory.vue' /* webpackChunkName: "pages/directory" */))
const _4d053890 = () => interopDefault(import('../pages/import.vue' /* webpackChunkName: "pages/import" */))
const _fe9afe2c = () => interopDefault(import('../pages/linkcards_no.vue' /* webpackChunkName: "pages/linkcards_no" */))
const _5a52c226 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1c9796e4 = () => interopDefault(import('../pages/login_old.vue' /* webpackChunkName: "pages/login_old" */))
const _3d168b1d = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _24861b25 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _543e5175 = () => interopDefault(import('../pages/signature.vue' /* webpackChunkName: "pages/signature" */))
const _37377e4e = () => interopDefault(import('../pages/signatures.vue' /* webpackChunkName: "pages/signatures" */))
const _5fd599d7 = () => interopDefault(import('../pages/solutions.vue' /* webpackChunkName: "pages/solutions" */))
const _153dd402 = () => interopDefault(import('../pages/uikit.vue' /* webpackChunkName: "pages/uikit" */))
const _53d60d76 = () => interopDefault(import('../pages/users.vue' /* webpackChunkName: "pages/users" */))
const _dca1dafe = () => interopDefault(import('../pages/wizard/index.vue' /* webpackChunkName: "pages/wizard/index" */))
const _5bef0572 = () => interopDefault(import('../pages/leads/landings.vue' /* webpackChunkName: "pages/leads/landings" */))
const _6af7eea4 = () => interopDefault(import('../pages/leads/linkcards.vue' /* webpackChunkName: "pages/leads/linkcards" */))
const _291b2346 = () => interopDefault(import('../pages/leads/settings_landings.vue' /* webpackChunkName: "pages/leads/settings_landings" */))
const _434f8b50 = () => interopDefault(import('../pages/leads/settings_linkcards.vue' /* webpackChunkName: "pages/leads/settings_linkcards" */))
const _94d93b2e = () => interopDefault(import('../pages/loyalty/loyaltys.vue' /* webpackChunkName: "pages/loyalty/loyaltys" */))
const _72e848de = () => interopDefault(import('../pages/loyalty/memberships.vue' /* webpackChunkName: "pages/loyalty/memberships" */))
const _955e7a94 = () => interopDefault(import('../pages/onboarding/landing.vue' /* webpackChunkName: "pages/onboarding/landing" */))
const _bf1c9a0a = () => interopDefault(import('../pages/onboarding/linkcard.vue' /* webpackChunkName: "pages/onboarding/linkcard" */))
const _3ae0c536 = () => interopDefault(import('../pages/onboarding/loyalty.vue' /* webpackChunkName: "pages/onboarding/loyalty" */))
const _fe135caa = () => interopDefault(import('../pages/sector/import.vue' /* webpackChunkName: "pages/sector/import" */))
const _07d6d2b6 = () => interopDefault(import('../pages/stats/linkcards.vue' /* webpackChunkName: "pages/stats/linkcards" */))
const _6301bbe4 = () => interopDefault(import('../pages/wizard/data.js' /* webpackChunkName: "pages/wizard/data" */))
const _05a46344 = () => interopDefault(import('../pages/sector/sectors/landings.vue' /* webpackChunkName: "pages/sector/sectors/landings" */))
const _0a64e251 = () => interopDefault(import('../pages/sector/sectors/linkcards.vue' /* webpackChunkName: "pages/sector/sectors/linkcards" */))
const _6f074eef = () => interopDefault(import('../pages/wizard/steps/sector.vue' /* webpackChunkName: "pages/wizard/steps/sector" */))
const _28c3f7c0 = () => interopDefault(import('../pages/wizard/steps/templates.vue' /* webpackChunkName: "pages/wizard/steps/templates" */))
const _7965c503 = () => interopDefault(import('../pages/wizard/steps/type.vue' /* webpackChunkName: "pages/wizard/steps/type" */))
const _bae3163a = () => interopDefault(import('../pages/wizard/steps/upgrade.vue' /* webpackChunkName: "pages/wizard/steps/upgrade" */))
const _dea9c1e2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1f60c884 = () => interopDefault(import('../pages/sector/sectors/_type/index.vue' /* webpackChunkName: "pages/sector/sectors/_type/index" */))
const _2e52bc96 = () => interopDefault(import('../pages/stats/landings/_sector.vue' /* webpackChunkName: "pages/stats/landings/_sector" */))
const _1e4cc437 = () => interopDefault(import('../pages/sector/sectors/_type/_sector.vue' /* webpackChunkName: "pages/sector/sectors/_type/_sector" */))
const _f76c9524 = () => interopDefault(import('../pages/editor/_id/index.vue' /* webpackChunkName: "pages/editor/_id/index" */))
const _7c857a6c = () => interopDefault(import('../pages/leads/_sector.vue' /* webpackChunkName: "pages/leads/_sector" */))
const _7c0bf874 = () => interopDefault(import('../pages/loyalty/_loyalty/index.vue' /* webpackChunkName: "pages/loyalty/_loyalty/index" */))
const _2aaa3397 = () => interopDefault(import('../pages/sectors/_type/index.vue' /* webpackChunkName: "pages/sectors/_type/index" */))
const _222d0d40 = () => interopDefault(import('../pages/templates/_type.vue' /* webpackChunkName: "pages/templates/_type" */))
const _5be9d43e = () => interopDefault(import('../pages/loyalty/_loyalty/import.vue' /* webpackChunkName: "pages/loyalty/_loyalty/import" */))
const _6df3418d = () => interopDefault(import('../pages/loyalty/_loyalty/members.vue' /* webpackChunkName: "pages/loyalty/_loyalty/members" */))
const _768cf113 = () => interopDefault(import('../pages/loyalty/_loyalty/stats.vue' /* webpackChunkName: "pages/loyalty/_loyalty/stats" */))
const _5ad360e8 = () => interopDefault(import('../pages/sector/_sector/landings.vue' /* webpackChunkName: "pages/sector/_sector/landings" */))
const _489f01ee = () => interopDefault(import('../pages/sector/_sector/linkcards.vue' /* webpackChunkName: "pages/sector/_sector/linkcards" */))
const _27396f67 = () => interopDefault(import('../pages/linkcards/_uid/_sid/index.vue' /* webpackChunkName: "pages/linkcards/_uid/_sid/index" */))
const _69b47362 = () => interopDefault(import('../pages/sharing/_user/_id/index.vue' /* webpackChunkName: "pages/sharing/_user/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _34e0c5aa,
    name: "account"
  }, {
    path: "/admin",
    component: _7bcb19e8,
    name: "admin"
  }, {
    path: "/contacts",
    component: _760136a6,
    name: "contacts"
  }, {
    path: "/dashboard",
    component: _6e51555e,
    name: "dashboard"
  }, {
    path: "/directory",
    component: _160cf4aa,
    name: "directory"
  }, {
    path: "/import",
    component: _4d053890,
    name: "import"
  }, {
    path: "/linkcards_no",
    component: _fe9afe2c,
    name: "linkcards_no"
  }, {
    path: "/login",
    component: _5a52c226,
    name: "login"
  }, {
    path: "/login_old",
    component: _1c9796e4,
    name: "login_old"
  }, {
    path: "/logout",
    component: _3d168b1d,
    name: "logout"
  }, {
    path: "/notifications",
    component: _24861b25,
    name: "notifications"
  }, {
    path: "/signature",
    component: _543e5175,
    name: "signature"
  }, {
    path: "/signatures",
    component: _37377e4e,
    name: "signatures"
  }, {
    path: "/solutions",
    component: _5fd599d7,
    name: "solutions"
  }, {
    path: "/uikit",
    component: _153dd402,
    name: "uikit"
  }, {
    path: "/users",
    component: _53d60d76,
    name: "users"
  }, {
    path: "/wizard",
    component: _dca1dafe,
    name: "wizard"
  }, {
    path: "/leads/landings",
    component: _5bef0572,
    name: "leads-landings"
  }, {
    path: "/leads/linkcards",
    component: _6af7eea4,
    name: "leads-linkcards"
  }, {
    path: "/leads/settings_landings",
    component: _291b2346,
    name: "leads-settings_landings"
  }, {
    path: "/leads/settings_linkcards",
    component: _434f8b50,
    name: "leads-settings_linkcards"
  }, {
    path: "/loyalty/loyaltys",
    component: _94d93b2e,
    name: "loyalty-loyaltys"
  }, {
    path: "/loyalty/memberships",
    component: _72e848de,
    name: "loyalty-memberships"
  }, {
    path: "/onboarding/landing",
    component: _955e7a94,
    name: "onboarding-landing"
  }, {
    path: "/onboarding/linkcard",
    component: _bf1c9a0a,
    name: "onboarding-linkcard"
  }, {
    path: "/onboarding/loyalty",
    component: _3ae0c536,
    name: "onboarding-loyalty"
  }, {
    path: "/sector/import",
    component: _fe135caa,
    name: "sector-import"
  }, {
    path: "/stats/linkcards",
    component: _07d6d2b6,
    name: "stats-linkcards"
  }, {
    path: "/wizard/data",
    component: _6301bbe4,
    name: "wizard-data"
  }, {
    path: "/sector/sectors/landings",
    component: _05a46344,
    name: "sector-sectors-landings"
  }, {
    path: "/sector/sectors/linkcards",
    component: _0a64e251,
    name: "sector-sectors-linkcards"
  }, {
    path: "/wizard/steps/sector",
    component: _6f074eef,
    name: "wizard-steps-sector"
  }, {
    path: "/wizard/steps/templates",
    component: _28c3f7c0,
    name: "wizard-steps-templates"
  }, {
    path: "/wizard/steps/type",
    component: _7965c503,
    name: "wizard-steps-type"
  }, {
    path: "/wizard/steps/upgrade",
    component: _bae3163a,
    name: "wizard-steps-upgrade"
  }, {
    path: "/",
    component: _dea9c1e2,
    name: "index"
  }, {
    path: "/sector/sectors/:type",
    component: _1f60c884,
    name: "sector-sectors-type"
  }, {
    path: "/stats/landings/:sector?",
    component: _2e52bc96,
    name: "stats-landings-sector"
  }, {
    path: "/sector/sectors/:type?/:sector",
    component: _1e4cc437,
    name: "sector-sectors-type-sector"
  }, {
    path: "/editor/:id",
    component: _f76c9524,
    name: "editor-id"
  }, {
    path: "/leads/:sector?",
    component: _7c857a6c,
    name: "leads-sector"
  }, {
    path: "/loyalty/:loyalty",
    component: _7c0bf874,
    name: "loyalty-loyalty"
  }, {
    path: "/sectors/:type",
    component: _2aaa3397,
    name: "sectors-type"
  }, {
    path: "/templates/:type?",
    component: _222d0d40,
    name: "templates-type"
  }, {
    path: "/loyalty/:loyalty?/import",
    component: _5be9d43e,
    name: "loyalty-loyalty-import"
  }, {
    path: "/loyalty/:loyalty?/members",
    component: _6df3418d,
    name: "loyalty-loyalty-members"
  }, {
    path: "/loyalty/:loyalty?/stats",
    component: _768cf113,
    name: "loyalty-loyalty-stats"
  }, {
    path: "/sector/:sector?/landings",
    component: _5ad360e8,
    name: "sector-sector-landings"
  }, {
    path: "/sector/:sector?/linkcards",
    component: _489f01ee,
    name: "sector-sector-linkcards"
  }, {
    path: "/linkcards/:uid?/:sid",
    component: _27396f67,
    name: "linkcards-uid-sid"
  }, {
    path: "/sharing/:user?/:id",
    component: _69b47362,
    name: "sharing-user-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
